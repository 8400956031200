<template>
  <div class="auths-timer">
    <svg class="auths-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <g class="auths-timer__circle">
        <circle class="auths-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
        <path
          :stroke-dasharray="circleDasharray"
          class="auths-timer__path-remaining"
          :class="remainingPathColor"
          d="
            M 50, 50
            m -45, 0
            a 45,45 0 1,0 90,0
            a 45,45 0 1,0 -90,0
          "
        ></path>
      </g>
    </svg>

    <span class="auths-timer__label">{{ formattedTimeLeft }}</span>

    <v-dialog :value="timeout" persistent max-width="450">
      <v-card>
        <v-card-title class="headline">
          <span class="mx-auto">{{ $t('auths.timeout.title') }}</span>
        </v-card-title>
        <v-card-text>
          <span>{{ $t('auths.timeout.message') }}</span>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AuthsTimer',
  data() {
    return {
      timer: null,
    };
  },
  computed: {
    ...mapGetters({
      availableTime: 'authsTimer/availableTime',
      timePassed: 'authsTimer/timePassed',
      fullDashArray: 'authsTimer/fullDashArray',
      colors: 'authsTimer/colors',
      timeout: 'authsTimer/timeout',
    }),
    circleDasharray() {
      return `${(this.timeFraction * this.fullDashArray).toFixed(0)} 283`;
    },
    formattedTimeLeft() {
      const timeLeft = this.timeLeft;
      const minutes = Math.floor(timeLeft / 60);
      let seconds = timeLeft % 60;

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      return `${minutes}:${seconds}`;
    },
    timeLeft() {
      return this.availableTime - this.timePassed;
    },
    timeFraction() {
      const rawTimeFraction = this.timeLeft / this.availableTime;
      return rawTimeFraction - (1 / this.availableTime) * (1 - rawTimeFraction);
    },
    remainingPathColor() {
      const { alert, warning, info } = this.colors;

      if (this.timeLeft <= alert.threshold) {
        return alert.color;
      } else if (this.timeLeft <= warning.threshold) {
        return warning.color;
      } else {
        return info.color;
      }
    },
  },
  mounted() {
    this.startTimer();
  },
  methods: {
    ...mapActions({
      startTimer: 'authsTimer/startTimer',
    }),
  },
};
</script>

<style lang="scss" scoped>
.auths-timer {
  position: relative;
  width: 60px;
  height: 60px;

  &__svg {
    transform: scaleX(-1);
  }

  &__circle {
    fill: none;
    stroke: none;
  }

  &__path-elapsed {
    stroke-width: 8px;
    stroke: grey;
  }

  &__path-remaining {
    stroke-width: 8px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;

    &.green {
      color: lightgreen;
    }

    &.orange {
      color: orange;
    }

    &.red {
      color: red;
    }
  }

  &__label {
    position: absolute;
    width: 60px;
    height: 60px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
  }
}
</style>
