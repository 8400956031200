<template>
  <v-container
    class="px-0 px-md-10 authentication-layout"
    :style="`background-image: url(${workspaceBackground})`"
    fill-height
    fluid
  >
    <template v-if="isLoading">
      <Loader />
    </template>

    <template v-else>
      <v-toolbar class="mt-5 px-0 authentication-layout__toolbar" width="100%" dense flat dark>
        <AuthsTimer v-if="canShowAuthsTimerAndSnack" />
        <v-spacer></v-spacer>
        <LanguageToggle :locale="currentLocale" @onLocaleChanged="changeLocale($event)" />
      </v-toolbar>

      <v-main>
        <v-container class="authentication-layout__content" fill-height fluid>
          <v-snackbar
            v-if="canShowAuthsTimerAndSnack"
            :timeout="-1"
            :value="sentToMobileInfo"
            absolute
            top
            elevation="24"
          >
            {{ $t('auths.sent_to_mobile') }}

            <template v-slot:action="{ attrs }">
              <v-btn color="red" text v-bind="attrs" @click="sentToMobileInfo = false">
                {{ $t('general.close') }}
              </v-btn>
            </template>
          </v-snackbar>

          <slot></slot>
        </v-container>
      </v-main>

      <v-toolbar
        class="mx-3 mb-5 mx-md-0 authentication-layout__footer"
        width="100%"
        dense
        flat
        dark
      >
        <v-spacer></v-spacer>
        <span>Powered by</span>
        <img
          src="/images/signi_cz_rgb_color_neg_transparent.png"
          width="auto"
          height="25px"
          alt=""
        />
        <v-spacer></v-spacer>
      </v-toolbar>
    </template>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { changeLocaleWithoutSave, getCurrentLocale } from '@/plugins/i18n';
import LanguageToggle from '@/plugins/i18n/components/LanguageToggle';
import Loader from '@/components/Loader';
import AuthsTimer from '@verification/components/AuthsTimer';
import gql from 'graphql-tag';

export default {
  name: 'VerificationLayout',
  components: {
    AuthsTimer,
    Loader,
    LanguageToggle,
  },
  apollo: {
    livenessCheckForToken: {
      query: gql`
        query livenessCheckForToken($token: String!) {
          livenessCheckForToken(token: $token) {
            state
            livenessPercentage
          }
        }
      `,
      variables() {
        return {
          token: this.authToken,
        };
      },
    },
  },
  data() {
    return {
      loaded: false,
      circularFetcher: null,
      livenessCheckForToken: null,
    };
  },
  computed: {
    ...mapGetters({
      authsSent: 'auths/isSent',
      isMicroTransactionDone: 'authsMicroTransaction/isMicroTransactionDone',
      isAmlType: 'auths/isAmlType',
      isBankIdType: 'auths/isBankIdType',
      isBankIdVerificationDone: 'authsBankId/authsBankIdDone',
      isDocumentsVerificationDone: 'authsDocument/isDocumentDone',
      isPoliticalVerificationDone: 'authsPolitical/isPoliticalDone',
      isSmsVerificationDone: 'authsSms/isSmsDone',
      modeEditing: 'auths/modeEditing',
      modeDispatchToMobile: 'auths/modeDispatchToMobile',
      isWithoutDocumentVerification: 'authsDocument/isWithoutDocumentVerification',
      isWithoutPoliticalVerification: 'authsPolitical/isWithoutPoliticalVerification',
      isWithoutMicroTransactionVerification:
        'authsMicroTransaction/isWithoutMicroTransactionVerification',
      isWithoutSmsVerification: 'authsSms/isWithoutSmsVerification',
      workspaceBackground: 'auths/workspaceBackground',
    }),
    sentToMobileInfo: {
      get() {
        return this.modeDispatchToMobile;
      },
      set(value) {
        this.setModeDispatchToMobile(value);
      },
    },
    authToken() {
      return this.$route.params.authToken;
    },
    livelinessCheckDone() {
      return typeof this.livenessCheckForToken?.state !== 'undefined';
    },
    authsStates() {
      return [
        {
          type: 'choice',
          isDone: this.isAmlType || this.isBankIdType,
          routeName: 'authentication',
        },
        {
          type: 'political',
          isDone: this.isPoliticalVerificationDone || this.isWithoutPoliticalVerification,
          routeName: 'authentication-questionnaire',
        },
        {
          type: 'liveliness',
          isDone: this.livelinessCheckDone,
          routeName: 'authentication-liveliness',
        },
        {
          type: 'sms',
          isDone: this.isSmsVerificationDone || this.isWithoutSmsVerification,
          routeName: 'authentication-sms',
        },
        {
          type: 'document',
          isDone: this.isDocumentsVerificationDone || this.isWithoutDocumentVerification,
          routeName: 'authentication-identity',
        },
        {
          type: 'micropayment',
          isDone: this.isMicroTransactionDone || this.isWithoutMicroTransactionVerification,
          routeName: 'authentication-micropayment',
        },
      ];
    },
    authsCompleted() {
      return this.authsStates?.every((item) => item.isDone) && this.authsSent;
    },
    currentLocale() {
      return getCurrentLocale(this);
    },
    isLoading() {
      return !this.loaded;
    },
    canShowAuthsTimerAndSnack() {
      return this.$route.name !== 'authentication-done';
    },
  },
  mounted() {
    this.loadAuthsData();

    if (this.isAmlType) {
      const self = this;
      this.circularFetcher = setInterval(() => {
        self.loadAuthsData();
      }, 5000);
    }
  },
  beforeDestroy() {
    clearInterval(this.circularFetcher);
  },
  methods: {
    ...mapActions({
      fetchAuths: 'auths/fetchAuths',
      setModeBackgroundUpdates: 'auths/setModeBackgroundUpdates',
      setModeDispatchToMobile: 'auths/setModeDispatchToMobile',
    }),
    activateAutoFetchingAuths() {
      const self = this;
      this.circularFetcher = setInterval(() => {
        self.loadAuthsData();
      }, 5000);
    },
    changeLocale(locale) {
      localStorage.setItem('sign_app_locale', locale);
      const isChangedLocale = changeLocaleWithoutSave(this, locale);

      if (isChangedLocale) {
        this.actualLocale = locale;
      }
    },
    goToPoint(name) {
      const authToken = this.authToken;
      const currentRouteName = this.$route.name;

      if ('authentication-done' === currentRouteName && null !== this.circularFetcher) {
        clearInterval(this.circularFetcher);
      }

      if (name === currentRouteName) {
        this.loaded = true;

        return false;
      }

      return this.$router.push({
        name,
        params: { authToken },
      });
    },
    loadAuthsData() {
      const authToken = this.authToken;

      this.fetchAuths(authToken).then(async () => {
        if (true === this.isBankIdType) {
          if (this.isBankIdVerificationDone) {
            return this.goToPoint('authentication-done');
          }

          this.loaded = true;

          return;
        }

        await this.$apollo.queries.livenessCheckForToken.refetch();

        this.loaded = true;

        if (this.modeEditing) {
          return;
        }

        if (this.authsCompleted) {
          this.setModeBackgroundUpdates(false);

          return this.goToPoint('authentication-done');
        }

        for (let auth of this.authsStates) {
          if (!auth.isDone) {
            return this.goToPoint(auth.routeName);
          }
        }

        return this.goToPoint('authentication-summary');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.authentication-layout {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;

  &__toolbar {
    background: transparent !important;
  }

  &__content {
    min-height: calc(100vh - 24px - 96px - 40px);
    height: 100%;
  }

  &__footer {
    border-top: solid 1px rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.1) !important;
    border-radius: 8px;
    font-size: 0.8rem;

    svg {
      display: inline-block;
      position: relative;
      top: 0.3rem;
      left: 0.4rem;
    }
  }
}
</style>
